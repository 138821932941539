<template>
  <div :key="isActive">
    <HomeUnPaid
      ref="unpaidComRef"
      :bottom="bottom"
      scene="home"
      :is-show="canDisplayUnpaid"
      :class="{
        'home-popover__hidden': closeStatus.unPaid
      }"
      :is-fold="isFold"
      @close-handle="closeHandle($event, 'closeUnPaid', 'unPaid')"
      @load-start="loadStart('unPaid')"
      @load-end="loadEnd($event, 'unPaid')"
    />
    <HomeOneClickPay
      ref="oneClickPayComRef"
      :bottom="bottom"
      scene="home"
      :is-show="canDisplayOneClickPay"
      :class="{
        'home-popover__hidden': closeStatus.oneClickPay
      }"
      :is-fold="isFold"
      @close-handle="closeHandle($event, 'closeOneClickPay', 'oneClickPay')"
      @load-start="loadStart('oneClickPay')"
      @load-end="loadEnd($event,'oneClickPay')"
    />
    <HomeCartNotifyPopover
      v-if="canDisplayHomeCartNotify"
      :is-show="showHomeCartNotifyPopover"
      @close-handle="closeHandle($event, 'closeHomeCartNotify', 'homeCartNotify')"
      @load-start="loadStart('homeCartNotify')"
      @load-end="loadEnd($event, 'homeCartNotify')"
    />
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import HomeOneClickPay from 'public/src/pages/common/oneClickPay/components/HomeOneClickPay.vue'
import HomeUnPaid from 'public/src/pages/components/UnpaidCom/index.vue'
import HomeCartNotifyPopover from 'public/src/pages/components/home-cart-notify-popover.vue'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import { abtservice } from '@/public/src/services/abt'
import { windowLinstnerScrollEventInstance } from 'public/src/services/expose/index.js'
import { mapMutations } from 'vuex'
import { createDebugRecorder } from '@/common/debug-print.js'

daEventCenter.addSubscriber({ modulecode: '2-22' })
const { addDebugMsgGetter, dispose } = createDebugRecorder('HomePopover')

export default {
  name: 'HomePopover',
  components: {
    HomeOneClickPay,
    HomeUnPaid,
    HomeCartNotifyPopover,
  },
  data() {
    return {
      isActive: true,
      isFold: false,
      isShow: true,
      curShowCompName: '',
      // 用户是否主动关闭的悬浮层
      closeStatus: {
        oneClickPay: getLocalStorage('closeOneClickPay') || false,
        unPaid: getLocalStorage('closeUnPaid') || false,
        homeCartNotify: false,
      },
      queen: ['unPaid', 'oneClickPay'],
      loadingMap: {},
      loadStatusMap: {},
      abtInfo: {},
      listentId: null,
      bottom: '1.5rem',
      originScrollTop: 0, // 浮层出现时的滚动高度
      distance: 300,
      isTouching: false,
      scrollType: 'down', // 鼠标滑动方向,up down
    }
  },
  computed: {
    // 最终页面是否允许展示待支付
    canDisplayUnpaid() {
      const showUnpaid = (
        this.loadStatusMap.unPaid == 1 
        && this.abtInfo?.p?.HomePaynow == 1
      )
      return showUnpaid
    },
    // 最终页面是否允许展示一键购
    canDisplayOneClickPay() {
      if (this.loadingMap.unPaid) {
        // 待支付正在加载中，不展示一键购
        return false
      }

      // 能展示待支付且未关闭待支付
      let blockByBefore = false
      if (this.abtInfo?.p?.HomePaynow == 1) {
        if (this.loadStatusMap.unPaid === undefined) {
          // 待支付 ABT 允许展示，但加载未完成
          // 不可展示一键购，避免出现一键购和待支付同时展示
          return false
        } else if (this.loadStatusMap.unPaid == 1) {
          // 待支付 ABT 允许展示，且加载完成
          // 则只有待支付关闭时，才能展示一键购
          blockByBefore = !this.closeStatus.unPaid
        } else {
          // 待支付 ABT 允许展示，但加载失败
          // 则按后续候补，可以展示一键购
        }
      }

      return (
        !blockByBefore
        && this.loadStatusMap.oneClickPay == 1 
        && this.abtInfo?.p?.HomeOneClickPay == 'Show' 
      )
    },
    // 最终页面是否允许展示购物车弹窗
    canDisplayHomeCartNotify() {
      if (
        this.loadingMap.unPaid
        || this.loadingMap.oneClickPay
      ) {
        // 前置优先级的项目正在加载中，不展示购物车弹窗
        return false
      }

      return (
        !this.showUnpaidOrOneClickPay // 未支付和一键购 优先级更高，它们不展示时才展示购物车弹窗
        && this.abtInfo?.p?.cart_pop_switch == 1 // ABT 命中
      )
    },

    showHomeCartNotifyPopover() {
      const closeStatusSnapshot = this.getCloseStatusSnapshot()

      return (
        closeStatusSnapshot.unPaid === true
        && closeStatusSnapshot.oneClickPay === true
        && closeStatusSnapshot.homeCartNotify === false
        && this.loadStatusMap.homeCartNotify === 1 // 预请求数据已经获取到
      )
    },
    showUnpaidOrOneClickPay() {
      return (
        (this.canDisplayUnpaid && !this.closeStatus.unPaid) // 可以展示待支付且未关闭待支付
        || (this.canDisplayOneClickPay && !this.closeStatus.oneClickPay) // 可以展示一键购且未关闭一键购
      )
    }
  },
  watch: {
    loadStatusMap: {
      handler(val) {
        this.distance = 300
        // 只调整待支付的入口收起时机
        if(val['unPaid'] == 1 && this.abtInfo?.p?.HomePaynow == 1 && this.abtInfo?.p?.HomePaynow_switch == '1'){
          const availHeight = window?.screen?.availHeight
          this.distance = availHeight || 300
        }
        const keys = Object.keys(val)
        if (keys.length >= 2) {
          this.isFold  && this.fold()
        }
      },
      deep: true
    },
    showUnpaidOrOneClickPay: {
      handler(val) {
        if(val){
          this.originScrollTop = window?.scrollY ?? 0
        }
        this.closeOrOpen(val)
        this.assignRootState({ hiddenBottomIcon: val })
      },
      immediate: true,
    },
    closeStatus: {
      handler() {
        this.onCloseStatusChange()
      },
      deep: true,
    }
  },
  activated() {
    this.isActive = !this.isActive
    // this.isFold = false
  },
  deactivated() {
    this.closeOrOpen(false)
    setTimeout(() => {
      this.loadStatusMap = {}
    }, 200)
    // 切换时关闭购物车弹窗
    window._gb_footer_.clear()
  },
  destroyed() {
    windowLinstnerScrollEventInstance.unListen(this.listentId)
    dispose()
  },
  async created() {
    // HomePaynow 0 1   HomeOneClickPay Hide Show
    // eslint-disable-next-line @shein-aidc/abt/abt
    const { HomeBottomPopup } = await abtservice.getUserAbtResult({ newPosKeys: 'HomeBottomPopup' })
    this.abtInfo = HomeBottomPopup || {}
  },
  mounted() {
    this.curShowCompName = this.queen.shift()
    this.$nextTick(() => {
      this.closeOrOpen(this.showUnpaidOrOneClickPay)
    })
    const innerHeight = window.innerHeight
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    this.listentId = windowLinstnerScrollEventInstance.listen(({ scrollTop, type }) => {
      this.handleScroll(scrollTop, type)
      if(!isSafari){
        return
      }
      if(innerHeight < window.innerHeight){
        this.bottom =  '2.4rem'
      } else {
        this.bottom =  '1.5rem'
      }
    })

    addDebugMsgGetter(() => {
      return [
        '\n',
        ` - canDisplayUnpaid: ${this.canDisplayUnpaid}\n`,
        ` - canDisplayOneClickPay: ${this.canDisplayOneClickPay}\n`,
        ` - canDisplayHomeCartNotify: ${this.canDisplayHomeCartNotify}\n`,
        ` - showHomeCartNotifyPopover: ${this.showHomeCartNotifyPopover}\n`,
        ` - showUnpaidOrOneClickPay: ${this.showUnpaidOrOneClickPay}\n`,
        ` - closeStatus: ${JSON.stringify(this.closeStatus)}\n`,
        ` - loadStatusMap: ${JSON.stringify(this.loadStatusMap)}\n`,
      ]
    })
  },
  methods: {
    ...mapMutations([
      'assignRootState',
    ]),
    closeOrOpen(val) {
      this.$nextTick(() => {
        const backTopEl = document.querySelector('.c-uptt')
        const bottomIcon = document.querySelector('.bottom-suspension')
        if (val) {
          bottomIcon?.classList?.add('home-popover__hidden')
          backTopEl?.classList?.add('home-popover__hidden')
        } else {
          bottomIcon?.classList?.remove('home-popover__hidden')
          backTopEl?.classList?.remove('home-popover__hidden')
        }
      })
    },
    // 现在到今日24:00:00秒的毫秒
    getTimeUntilMidnight() {
      const now = new Date() // 获取当前时间
      const endOfDay = new Date(now) // 创建一个当天的日期对象
      endOfDay.setHours(24, 0, 0, 0) // 设置时间为当天的 24:00:00
      // 计算时间差
      const timeDiff = endOfDay.getTime() - now.getTime()
      // 转换成秒数
      return Math.floor(timeDiff) // 毫秒
    },
    closeHandle(type, eventName, name) {
      this.closeOrOpen(false)
      // 用户主动关闭悬浮层
      if (type === 'active' || type === 'any') {
        this.$set(this.closeStatus, name, true)
      }
      if (type === 'active') {
        setLocalStorage({
          key: eventName,
          value: true,
          expire: this.getTimeUntilMidnight()
        })
      }
      //this.$refs.unpaidComRef.close()
    },
    loadStart(name) {
      this.$set(this.loadingMap, name, true)
    },
    loadEnd(type, name) {
      this.$set(this.loadStatusMap, name, type)
      this.$set(this.loadingMap, name, false)
      // if (type !== 1 || this.closeStatus[name]) { // 组件不展示
      //   this.curShowCompName = this.queen.shift()
      // }
    },
    fold() {
      this.$refs?.unpaidComRef?.packUpHandle()
      this.$refs?.oneClickPayComRef?.packUpHandle()
    },
    handleScroll(scrollTop) {
      // 当一键购或者待支付 出现以后时，以此时的滚动条位置为基准
      if(!this.showUnpaidOrOneClickPay){ // 如果手指没有触摸屏幕，不做处理
        return
      }

      // 滚动时关闭购物车弹窗
      window._gb_footer_.clear()

      if(!this.originScrollTop){
        this.originScrollTop = scrollTop
      }
      // if(this.scrollType !== type){
      //   console.log('gaibian', type, scrollTop)
      //   this.originScrollTop = scrollTop
      //   this.scrollType = type
      // }
      this.closeOrOpen(this.showUnpaidOrOneClickPay)
      if(this.$route?.name !== 'config_index'){
        return 
      }
      if (Math.abs(scrollTop - this.originScrollTop) >= this.distance && !this.isFold) {
        this.fold()
        this.isFold = true
      }
    },
    getCloseStatusSnapshot() {
      const closeStatusSnapshot = {
        ...this.closeStatus
      }

      // 对关闭状态表进行校正，因为有些类型的弹窗可能一直就并不满足展示条件
      // 但是我们在初始化关闭状态表的时候，一开始设置为 false，却一直等待并不会到来的 置为 true 动作（表示已关闭）
      // 因此，我们要将某些不会展示的弹窗的关闭状态设置为 true
      if (!this.canDisplayUnpaid) {
        closeStatusSnapshot.unPaid = true
      }
      if (!this.canDisplayOneClickPay) {
        closeStatusSnapshot.oneClickPay = true
      }
      if (!this.canDisplayHomeCartNotify) {
        closeStatusSnapshot.homeCartNotify = true
      }

      return closeStatusSnapshot
    },
    onCloseStatusChange() {
      const closeStatusSnapshot = this.getCloseStatusSnapshot()
      this.$emit('close-status-change', closeStatusSnapshot)
    }
  }
}
</script>

<style>
.home-popover__hidden {
  visibility: hidden;
  pointer-events: none;
}
</style>
