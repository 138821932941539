<template>
  <AsyncComponent
    ref="unpaidComBffRef"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script setup>
import { ref, defineAsyncComponent } from 'vue'
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
const AsyncComponent = defineAsyncComponent(() => {
  return getBffVersion('unpaid_com_v1')
    ? import('./bffUnpaidCom.vue')
    : import('./oldUnpaidCom.vue')
})
const unpaidComBffRef = ref(null)
const packUpHandle = () => {
  unpaidComBffRef.value.packUpHandle()
}
defineExpose({
  packUpHandle
})
</script>
